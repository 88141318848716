<template>
  <div class="language-dropdown2" @click="toggledropdown">
    <div class="dropdown2-button">
      <img :src="currentLanguage.flag" class="flag" />
      <span class="code">{{ currentLanguage.code }}</span>
      <span class="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="transform: scale(0.7)"><path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"/></svg></span>
    </div>
    <div class="dropdown2-menu" v-if="isOpen">
      <div
        class="dropdown2-item"
        v-for="(lang, index) in languages"
        :key="index"
        @click.stop="selectLanguage(lang)"
      >
        <img :src="lang.flag" class="flag" />
        <span class="code">{{ lang.code }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Languagedropdown',
  data() {
    return {
      locale: '',
      isOpen: false,
      currentLanguage: {
        code: 'BR',
        flag: 'https://flagcdn.com/w40/br.png',
        locale: 'pt',
      },
      languages: [
        { code: 'BR', flag: 'https://flagcdn.com/w40/br.png', locale: 'pt' },
        { code: 'EN', flag: 'https://flagcdn.com/w40/us.png', locale: 'en' },
        { code: 'ES', flag: 'https://flagcdn.com/w40/es.png', locale: 'es' },
        { code: 'ZH', flag: 'https://flagcdn.com/w40/cn.png', locale: 'zh' },
      ],
    };
  },
  mounted() {
    // Inicializa com o idioma atual do i18n
    const currentLocale = this.$i18n.locale;
    const currentLang = this.languages.find((lang) => lang.locale === currentLocale);
    if (currentLang) {
      this.currentLanguage = currentLang;
    }
  },
  methods: {
    toggledropdown() {
      this.isOpen = !this.isOpen;
    },
    selectLanguage(lang) {
      this.currentLanguage = lang;
      this.isOpen = false;
      this.$i18n.locale = lang.locale;
      localStorage.setItem('OptLang', lang.locale);
    },
  },
};
</script>

<style scoped>
.language-dropdown2 {
  position: relative;
  width: 87px;
  cursor: pointer;
  font-family: Arial, sans-serif;
}

.dropdown2-button {
  background-color: #FAFAFA;
  padding: 3px 3px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flag {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 6px;
}

.code {
  flex-grow: 1;
  font-size: 13px;
  text-align: left;
}

.arrow {
  font-size: 2px;
  margin-left: 6px;
}

.dropdown2-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-top: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000000;
}

.dropdown2-item {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  cursor: pointer;
}

.dropdown2-item:hover {
  background-color: #f0f0f0;
}
</style>
